<template ref="createInitiative">
  <div class="ls-create__wrapper">
    <LxpLoader v-if="isLoading" />
    <ValidationObserver
      ref="create-initiative-observer"
      @submit.prevent="submitForm()"
      v-else
    >
      <div class="ls-create__form">
        <div class="ls-create__name--input">
          <ValidationProvider
            name="Initiative name"
            rules="required"
            v-slot="{ errors }"
          >
            <LxpInput
              label="Initiative name*"
              :maxlength="250"
              showLimit
              v-model="createInitiative.name"
              :hasError="errors[0] ? true : false"
              :supportText="errors[0] ? errors[0] : ''"
            />
          </ValidationProvider>
        </div>
      </div>
      <ValidationProvider
        name="Short headline"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="ls-create__form">
          <LxpInput
            label="Short headline*"
            :maxlength="250"
            showLimit
            v-model="createInitiative.short_headline"
            :hasError="errors[0] ? true : false"
            :supportText="errors[0] ? errors[0] : ''"
          />
        </div>
      </ValidationProvider>
      <ValidationProvider name="Info" rules="required" v-slot="{ errors }">
        <div class="ls-create__form">
          <LxpTextarea
            label="Info*"
            placeholder="Please provide a brief description of this initiative, including its mission, services, and any other relevant information. Keep it professional and avoid sharing sensitive information."
            :limit="10000"
            :rows="6"
            showLimit
            v-model="createInitiative.info"
            :hasError="errors[0] ? true : false"
            :supportText="errors[0] ? errors[0] : ''"
          />
        </div>
      </ValidationProvider>

      <div class="ls-create__form">
        <LxpDragDrop
          id="dragdrop-initiative"
          noteText="Image should not exceed 1Mb. File format jpeg, jpg or png. Recommended size 960*640px"
          dragDropClass="ls-create__image"
          :dropIcon="
            require('@/assets/images/learning-space/drag-drop-image.svg')
          "
          :accept="['jpeg', 'jpg', 'png']"
          :authToken="authToken"
          :uploadedFileUrl="createInitiative.image"
          :fileUploadUrl="getUploadUrl()"
          @file-url="onCoverChange"
          @remove-file="onRemoveFile"
        />
      </div>

      <ValidationProvider
        name="url"
        :rules="{
          regex: /^https:\/\/(?:www\.)?(?:youtube\.com|youtu\.be|vimeo\.com|player\.vimeo\.com)\/.*/i
        }"
        v-slot="{ errors }"
      >
        <div class="ls-create__form">
          <LxpInput
            label="Enter your url video's ID "
            v-model="createInitiative.video_url"
            :hasError="errors[0] ? true : false"
            :supportText="
              errors[0]
                ? errors[0]
                : 'Only use an embedded video link from YouTube or Vimeo. You may find the link from the share-video options on YouTube or Vimeo.'
            "
          />
        </div>
      </ValidationProvider>
    </ValidationObserver>
    <div v-if="showError" class="ls-create__form_errMsg">
      <span>
        <img
          src="@/assets/images/learning-space/initiative/error.svg"
          alt="error"
        />
        <span class="error_msg--span">{{ errMsg }}</span>
      </span>
      <span class="error-msg__close" @click="onClose">
        <img
          src="@/assets/images/learning-space/initiative/crossmark.svg"
          alt="close"
        />
      </span>
    </div>
    <LxpToaster
      title="Error"
      :show.sync="showToast"
      variant="error"
      :message="toasterMessage"
    />
  </div>
</template>

<script>
import {
  LxpInput,
  LxpTextarea,
  LxpDragDrop,
  LxpLoader,
  LxpToaster
} from "didactica";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
import { mapGetters } from "vuex";

export default {
  components: {
    LxpInput,
    LxpLoader,
    LxpTextarea,
    LxpDragDrop,
    LxpToaster,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      createInitiative: {
        name: "",
        org: "",
        short_headline: "",
        info: "",
        image: "",
        video_url: ""
      },
      errorMessage: {
        SIZE: "Image size exceeds 1MB.",
        DIMENSION: "Image height exceeds 144 pixels.",
        UPLOAD: "Error uploading image."
      },
      authToken: "",
      isLoading: false,
      error: true,
      showError: false,
      errMsg: "",
      showToast: false,
      toasterMessage: ""
    };
  },
  computed: {
    ...mapGetters(["allConfig", "getOrgList"]),
    currentOrg() {
      return this.$route.params.org;
    }
  },

  created() {
    if (this.getOrgList.length === 0) {
      this.listOrganisation();
    }
    this.authToken = `Bearer ${this.$keycloak.token}`;
    this.addEvent();
  },

  methods: {
    listOrganisation() {
      this.isLoading = true;
      this.$store.dispatch("listAllOrganisation").then(() => {
        this.isLoading = false;
      });
    },
    onCoverChange(e) {
      this.createInitiative.image = e.image;
    },
    onRemoveFile() {
      this.createInitiative.image = "";
    },
    onClose() {
      this.showError = false;
    },
    async validate() {
      if (
        this.createInitiative.video_url !== "" &&
        this.createInitiative.image === ""
      ) {
        this.errMsg = "When using a video, image is mandatory";
        this.showError = true;
        return;
      }
      if (this.$refs["create-initiative-observer"]) {
        return this.$refs["create-initiative-observer"].validate();
      }
    },
    submitForm() {
      this.createInitiative.org = this.currentOrg;
      if (this.createInitiative.logo !== "") {
        this.$store
          .dispatch("createInitiative", this.createInitiative)
          .then(() => {
            this.$router.push({
              name: "SelectedLearningSpace",
              params: {
                org: this.currentOrg
              }
            });
            //emit
            this.$root.$emit("createInitiativeSave", {
              initiativeCreated: true,
              message: "Initiative got created successfully. ",
              title: "Created ! ",
              variant: "success"
            });
          })
          .catch(res => {
            const errorMessage = res.response.data.name;
            if (errorMessage) {
              this.toasterMessage = errorMessage;
              this.showToast = true;
            } else {
              this.toasterMessage =
                "Error on Save.  Please check and try again.";
              this.showToast = true;
            }
            this.addEvent();
          });
      }
    },
    getUploadUrl() {
      return `${this.allConfig.baseURL}/spaces/upload-image/`;
    },
    addEvent() {
      this.$root.$once("InitiativesSubmitForm", () => {
        this.validateAndSubmitForm();
      });
    },
    validateAndSubmitForm() {
      this.validate().then(isFormValid => {
        if (isFormValid) {
          this.submitForm();
        } else {
          this.addEvent();
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_learningspace.scss";
.ls-create__wrapper {
  .lxp-dd__error--div {
    height: 210px !important;
  }
}
</style>
